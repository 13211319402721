import { extendTheme } from '@chakra-ui/react';

const fonts = {
  heading: '"Inter", sans-serif',  // Inter for headings
  body: '"Inter", sans-serif',     // Inter for body text
  mono: '"Inter", sans-serif',     // Optional: Inter for monospaced text
};

const colors = {
  brand: {
    900: '#333333',  // Very dark gray for maximum contrast
    800: '#474747',  // Slightly lighter gray
    700: '#5C5C5C',  // Medium gray
  },
  primary: {
    100: '#F5F5F5',  // Very light gray, almost white
    200: '#E0E0E0',  // Light gray
    300: '#CCCCCC',  // Light medium gray
    400: '#B3B3B3',  // Neutral gray
    500: '#999999',  // Mid-tone gray
    600: '#808080',  // Medium-dark gray
    700: '#666666',  // Darker gray
    800: '#4D4D4D',  // Very dark gray
    900: '#333333',  // Almost black
  },
  gradient: {
    start: 'rgba(255, 255, 255, 1)',  // White
    end: 'rgba(0, 0, 0, 1)',          // Black
    lightShade: 'linear-gradient(to right, #E0E0E0, #FFFFFF)',  // Light shade gradient
    darkShade: 'linear-gradient(to right, #333333, #4D4D4D)'     // Dark shade gradient
  },
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: 'bold', // Example base style
    },
    variants: {
      solid: {
        bg: 'primary.500',
        color: 'white',
        _hover: {
          bg: 'primary.700',  // Darken button background on hover
        },
      },
      outline: {
        borderColor: 'primary.500',
        color: 'primary.500',
        _hover: {
          borderColor: 'primary.700',  // Darken border color on hover
          color: 'primary.700',        // Darken text color on hover
        },
      },
      // You can keep adding other variants as needed
    },
  },
};

const theme = extendTheme({ colors, fonts, components });

export default theme;
