// src/pages/UseCasePage.js
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import SolutionsSection from '../components/SolutionsSection';
import MainLayout from '../components/layouts/MainLayout';
import Hero from "../components/sections/Hero";

const UseCasePage = () => {
  const { useCaseId } = useParams();

  const content = useMemo(() => {
    switch (useCaseId) {
      case 'Onboarding':
        return (
          <>
            <Hero
              title="Onboarding"
              subtitle="Onboard more customers with less risk. From typing biometrics of customers to facematching algorithms, onboard good actors and reduce financial risk on your platform"
              image="/onboarding.jpg"
              ctaText="Get started"
            />
            <SolutionsSection
              problems="Digital onboarding experience for financial services has been changed drastically in recent years. Easier customer onboarding come with financial risks. By using our onboarding solutions, you can provide the best onboarding experience without damaging customer experience for good actors."
              solutions="Onboarding model, typing characteristics, address verification, website checker."
            />
          </>
        );
      case 'Banking':
        return (
          <>
            <Hero
              title="Banking"
              subtitle="Innovative intelligence solutions for banking. Traditional banking doesn't meet requirements and regulations of the modern era. Integrate our intelligence solutions to be compliant and reduce risks."
              image="/banking.jpg"
              ctaText="Get started"
            />
            <SolutionsSection
              problems="Traditional banking solutions don't scale and it doesn't satisfy the needs of the current financial services landscape. Income verification, name match and screening problems can now be solved with machine learning and smart algorithms. We power financial services to level up their intelligence so that they can serve their customers better."
              solutions="Name matching algorithms, device fingerprint, ip and payment reference solutions."
            />
          </>
        );
      case 'Fincrime':
        return (
          <>
            <Hero
              title="Fincrime"
              subtitle="Prevent financial crimes with machine learning models and advanced algorithms. Integrate to our Connect product to detect fraudulent events, entities and reduce financial risks."
              image="/fincrime.jpg"
              ctaText="Get started"
            />
            <SolutionsSection
              problems="Digital financial services increased the exposure of financial crimes in our daily lives. Fake customer documents, fake account names and addresses are some examples. By using our machine learning models and smart algorithms, you can reduce the financial risk in your platform."
              solutions="AI-driven fraud detection."
            />
          </>
        );
      default:
        return null;
    }
  }, [useCaseId]);

  return (
    <MainLayout>
      {content}
    </MainLayout>
  );
};

export default React.memo(UseCasePage);
