// src/pages/HomePage.js
import React from 'react';
import MainLayout from '../components/layouts/MainLayout';
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
  return (
    <MainLayout>
      <ContactForm/>
    </MainLayout>
  );
};

export default React.memo(ContactPage);
