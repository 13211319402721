// src/pages/HomePage.js
import React from 'react';
import Hero from "../components/sections/Hero";
import MainLayout from '../components/layouts/MainLayout';

const HomePage = () => {
  return (
    <MainLayout>
      <Hero
        title="One integration, and get linked to other financial services!"
        subtitle="Fintechlinx is a world-leading intelligence platform to help financial services cut costs related to fincrime risks, onboard and convert more customers with reduced risk."
        image="/lending_image_new_new.jpg"
        ctaText="Get started"
      />
    </MainLayout>
  );
};

export default React.memo(HomePage);
