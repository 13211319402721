import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';

const MainLayout = ({ children, setSelectedUseCase }) => {
  return (
    <>
      <Box width="100%" maxW={{ xl: '1200px' }} m="0 auto" p={4}>
        <Flex direction="column" align="center">
          <Header setSelectedUseCase={setSelectedUseCase} />
          {children}
        </Flex>
      </Box>
      <Footer />
    </>
  );
};

export default MainLayout;
