// src/components/SolutionsSection.js
import React from 'react';
import { Box, Heading, Text, VStack, Icon } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

const SolutionsSection = ({ problems, solutions }) => (
  <Box mt={10} p={6} bg="gray.50" borderRadius="lg">
    <VStack spacing={5} align="stretch">
      <Box>
        <Heading size="lg" mb={2}>Challenges</Heading>
        <Text fontSize="md" lineHeight="tall">{problems}</Text>
      </Box>
      <Box>
        <Heading size="lg" mb={2}>Solutions</Heading>
        <VStack spacing={3} align="stretch">
          {solutions.split('. ').map((solution, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Icon as={MdCheckCircle} color="green.500" mr={2} />
              <Text fontSize="md" lineHeight="tall">{solution}</Text>
            </Box>
          ))}
        </VStack>
      </Box>
    </VStack>
  </Box>
);

export default SolutionsSection;
