import React from 'react';
import { Box, Flex, Image, Heading, Stack } from '@chakra-ui/react';

export default function Hero({ title, subtitle, image }) {
  return (
    <Flex
      align="center"
      justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
      direction={{ base: 'column-reverse', md: 'row' }}
      minH="70vh"
      px={8}
      mb={16}
    >
      <Stack
        spacing={4}
        w={{ base: '80%', md: '40%' }}
        align={['center', 'center', 'flex-start', 'flex-start']}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={['center', 'center', 'left', 'left']}
        >
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={['center', 'center', 'left', 'left']}
        >
          {subtitle}
        </Heading>
      </Stack>
      <Box
        w={{ base: '80%', md: '55%' }}
        mb={{ base: 12, md: 0 }}
      >
        <Image
          src={image}
          rounded="1rem"
          shadow="none"
          width="full"
          height="full"
          objectFit="contain"
        />
      </Box>
    </Flex>
  );
}

Hero.defaultProps = {
  title: 'React landing page with Chakra UI',
  subtitle: 'This is the subheader section where you describe the basic benefits of your product'
};
