import React from 'react';
import { Box, Flex, Button, Menu, MenuButton, MenuList, MenuItem, Image, Link, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useCases } from '../../useCases';

const Header = () => {
  const navigate = useNavigate();

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  return (
    <Box p={4} color="primary.800" width="full">
      <Flex justify="space-between" align="center" maxW={{ xl: '1200px' }} m="0 auto" wrap="nowrap">
        <Link as={RouterLink} to="/" style={{ textDecoration: 'none' }}>
          <Flex align="center" wrap="nowrap">
            <Image src="/linx_logo.jpg" alt="Logo" boxSize="40px" />
            <Text fontSize="xl" fontWeight="bold" color="brand.900" ml={2} noOfLines={1}>
              LINX
            </Text>
          </Flex>
        </Link>
        <Flex justify="flex-end" align="center" flex="1" ml={4} wrap="nowrap" minW="0">
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} color="primary.800" variant="ghost" minW="auto">
              Use Cases
            </MenuButton>
            <MenuList color="primary.800">
              {useCases.map((useCase) => (
                <MenuItem
                  key={useCase}
                  onClick={() => handleMenuItemClick(`/use-case/${useCase}`)}
                >
                  {useCase}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Button
            as={RouterLink}
            to="/contact"
            ml={4}
            color="primary.800"
            variant="link"
            _hover={{ textDecoration: 'underline' }}
            minW="auto"
          >
            Get Started
          </Button>
          {/*<Button as={RouterLink} to="/login" ml={2} color="primary.800" variant="link" _hover={{ textDecoration: 'underline' }}>
            Login
          </Button>*/}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
