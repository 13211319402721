import React, { useState } from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

const ContactForm = () => {
  const formWidth = useBreakpointValue({ base: '90%', md: '700px', lg: '800px', xl: '1000px' });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    companyEmail: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:fintechlinx@gmail.com?subject=Contact%20Form%20Submission&body=First%20Name:%20${formData.firstName}%0D%0ALast%20Name:%20${formData.lastName}%0D%0ACompany%20Name:%20${formData.companyName}%0D%0ACompany%20Email:%20${formData.companyEmail}`;
    window.location.href = mailtoLink;
  };

  return (
    <Box p={6} borderRadius="md" bg="white" boxShadow="md" width={formWidth} mx="auto">
      <Heading as="h2" size="lg" mb={6} textAlign="center" color="brand.900">
        Contact Us
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="firstName" isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              placeholder="First Name"
              focusBorderColor="primary.500"
              value={formData.firstName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="lastName" isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              placeholder="Last Name"
              focusBorderColor="primary.500"
              value={formData.lastName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="companyName" isRequired>
            <FormLabel>Company Name</FormLabel>
            <Input
              placeholder="Company Name"
              focusBorderColor="primary.500"
              value={formData.companyName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="companyEmail" isRequired>
            <FormLabel>Company Email</FormLabel>
            <Input
              type="email"
              placeholder="Company Email"
              focusBorderColor="primary.500"
              value={formData.companyEmail}
              onChange={handleChange}
            />
          </FormControl>
          <Button
            mt={4}
            colorScheme="primary"
            bg="primary.500"
            color="white"
            _hover={{ bg: 'primary.700' }}
            type="submit"
            width="full"
          >
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ContactForm;
