// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import UseCasePage from './pages/UseCasePage';
import theme from './theme/theme';

const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
        <Route path="/use-case/:useCaseId" element={<UseCasePage />} />
      </Routes>
    </Router>
  </ChakraProvider>
);

export default App;
