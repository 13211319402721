import { ReactNode } from 'react';
import { Box, Container, SimpleGrid, Stack, Text, useColorModeValue } from '@chakra-ui/react';

const Logo = (props: any) => {
  return (
    <svg height={32} viewBox="0 0 120 28" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* SVG content here */}
    </svg>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('primary.900', 'primary.900')}
      color={useColorModeValue('white', 'white')}
      width="full"
    >
      <Container as={Stack} maxW={'6xl'} py={10} mx="auto">
        <SimpleGrid templateColumns={{ sm: '1fr 1fr', md: '5fr 1fr 1fr' }} spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Logo />
            </Box>
            <Text fontSize={'sm'}>© 2022 Chakra Templates. All rights reserved</Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Text as="a" href={'#'}>Terms of Service</Text>
            <Text as="a" href={'#'}>Legal</Text>
            <Text as="a" href={'#'}>Privacy Policy</Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            <Text as="a" href={'https://www.linkedin.com/company/fintechlinx/'}>Twitter</Text>
            <Text as="a" href={'https://www.linkedin.com/company/fintechlinx/'}>Instagram</Text>
            <Text as="a" href={'https://www.linkedin.com/company/fintechlinx/'}>LinkedIn</Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
